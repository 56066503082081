import React from "react"
import { Link } from "gatsby"
import HubspotForm from "./hubspot-form"
import { useTheme } from "gatsby-interface"

const ContactUsForm = () => {
  const { colors, space } = useTheme()
  return (
    <div
      css={theme => ({ marginBottom: theme.space[12], maxWidth: 800 })}
      id="contact-us"
    >
      <h3>Get in Touch</h3>
      <HubspotForm
        portalId="4731712"
        formId="05ca01d7-486d-476d-ae3c-8a14038defd0"
        sfdcCampaignId="701f4000000UwWDAA0"
        wrapperCss={{
          padding: space[8],
          backgroundColor: colors.purple[5],
          // "Yes/ No" radio buttons on /support
          ".inputs-list.multi-container": {
            "& .hs-form-radio": {
              display: `inline-flex`,
              alignItems: `center`,
              marginRight: space[7],
              marginTop: space[3],
            },
          },
        }}
      />
      <p css={theme => ({ marginTop: theme.space[5] })}>
        Need help with Gatsby? Visit our{" "}
        <Link to="/support">support portal here!</Link>
      </p>
    </div>
  )
}

export default ContactUsForm
