import React from "react"
import PropTypes from "prop-types"
import hex2rgba from "hex2rgba"

import Container from "../../components/container"
import SkewedBackground from "../../components/decoration/skewed-background"
import Section from "./index"
import CTA from "./cta"
import Title from "../hero/title"

import { useTheme } from "gatsby-interface"
import { rhythm } from "../../utils/typography"
import { bodyCopy } from "../../utils/styles"

const HomeFeatures = ({ section, layout }) => {
  const theme = useTheme()

  const featuresContainerCss = {
    background: `linear-gradient(180deg, ${hex2rgba(`#4A90E2`, 0.05)} 0,
      ${hex2rgba(`#4A90E2`, 0.04)}
     100%)`,
    marginBottom: theme.space[10],
    paddingBottom: theme.space[10],
    position: `relative`,
    overflow: `hidden`,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.mediaQueries.phablet]: {
      width: `100%`,
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
    },
    [theme.mediaQueries.tablet]: {
      borderRadius: theme.radii[1],
      display: `flex`,
      marginTop: theme.space[13],
      marginBottom: theme.space[13],
      paddingLeft: theme.space[8],
      paddingRight: theme.space[8],
      // width: `100%`,
    },
  }

  const featuresInnerContainerCss = {
    paddingTop: theme.space[13],
    paddingBottom: `16vh`,
    [theme.mediaQueries.tablet]: {
      paddingTop: `7.5rem`,
      paddingBottom: `7.5rem`,
    },
  }

  const customBodyCopyCss = {
    color: theme.colors.colors.blue[90],
    maxWidth: rhythm(20),
    margin: `auto`,
    paddingBottom: theme.space[10],
    textAlign: `center`,
  }

  return (
    <Container
      customStyles={featuresContainerCss}
      innerCustomStyles={featuresInnerContainerCss}
    >
      <Title
        customStyles={{
          color: theme.colors.colors.blue[90],
          textAlign: `center`,
        }}
      >
        {section.title}
      </Title>
      {section.text && section.text.childMarkdownRemark && (
        <div
          css={[bodyCopy, customBodyCopyCss]}
          dangerouslySetInnerHTML={{
            __html: section.text.childMarkdownRemark.html,
          }}
        />
      )}
      <Section section={section} layout={layout} />
      <CTA section={section} />
      <SkewedBackground shape="wave" position="bottom" height="4" />
    </Container>
  )
}

HomeFeatures.propTypes = {
  section: PropTypes.object.isRequired,
  layout: PropTypes.object,
}

export default HomeFeatures
