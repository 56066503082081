import React from "react"
import { graphql, Link } from "gatsby"

import { guideContentBlockStyles } from "../utils/styles"

const Webinar = ({ webinar }) => (
  <div css={guideContentBlockStyles}>
    <h3 css={{ marginBottom: 0 }}>{webinar.name}</h3>
    <p>{webinar.subtitle.subtitle}</p>
    <Link to={`/${webinar.slug}`}>{webinar?.formHeader?.formHeader}</Link>
  </div>
)

export default Webinar

export const WebinarFragment = graphql`
  fragment WebinarFragment on ContentfulLandingPageConnection {
    edges {
      node {
        title
        subtitle {
          subtitle
        }
        name
        slug
        createdAt
        formHeader {
          formHeader
        }
      }
    }
  }
`
